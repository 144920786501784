import React from 'react'
import { StatusUpdateForm, Textarea } from 'react-activity-feed'
import 'react-activity-feed/dist/index.css'
import styled from 'styled-components'

import { useMentions } from '../../hooks/useMentions'

const StyledStatusUpdateForm = styled(StatusUpdateForm)`
  .raf-panel-header {
    border-bottom: 1px solid #333;
    padding: 5px;
  }

  .raf-button--primary {
    background-color: var(--theme-color);
    color: black;
    border-radius: 30px;
    transition: background-color 0.2s;

    &:disabled {
      background-color: #555;
    }

    &:hover {
      background-color: var(--faded-theme-color);
    }
  }

  .rta__textarea {
    background-color: black;
    border: none;
    padding: 15px;
    font-size: 20px;
    font-weight: 500;
    color: #e0e0e0;
    resize: none;
    overflow: hidden;

    &:focus {
      background-color: black;
      outline: 2px solid var(--theme-color);
      border-radius: 3px;
    }
  }

  .rta__list {
    border: 1px solid #333;
  }

  .rta__entity {
    background-color: #222;
    color: #fff;
    padding: 2px 5px;
    font-size: 16px;
    font-weight: 500;
  }

  .rta__entity--selected {
    background-color: #555;
    color: #fff;
    padding: 2px 5px;
    font-size: 16px;
    font-weight: 500;
  }
`

const CreatePostForm = (props) => {
  const { mentionsTrigger } = useMentions()

  return (
    <StyledStatusUpdateForm
      {...props}
      Textarea={(textareaProps) => (
        <Textarea
          {...textareaProps}
          className="rta__textarea"
          placeholder="Share a flight!"
        />
      )}
      trigger={mentionsTrigger}
    />
  )
}

export default CreatePostForm
