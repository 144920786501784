import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStreamContext } from 'react-activity-feed'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import styled from 'styled-components'
import axios from 'axios'

import { updateProfileImage } from '../../contexts/auth/AuthSlice'
import { useAuth } from '../../contexts/auth/useAuth'
import UserPlaceholder from '../Icons/UserPlaceholder'

const Container = styled.div`
  display: flex;
  border-radius: 50%;
`

const StyledImage = styled.img`
  object-fit: cover;
  cursor: pointer;
  border-radius: 50%;
`

const UserImage = ({
  src,
  alt,
  username,
  userId,
  clickable = true,
  expandable = false,
}) => {
  const navigate = useNavigate()
  const { authState } = useAuth()
  const { client } = useStreamContext()
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)
  const [key, setKey] = useState(0)
  const [error, setError] = useState(false)

  useEffect(() => {
    setTimeout(() => setKey(key + 1))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLightboxOpen])

  useEffect(() => {
    const handleImageReplace = async () => {
      if (userId && src && src.includes('googleusercontent.com')) {
        await uploadImageToStream(
          src,
          userId,
          client,
          authState.authUser.feedToken
        )
      }
    }

    handleImageReplace()
  }, [src, userId, client, authState.authUser.feedToken])

  const handleClick = (e) => {
    e.preventDefault()
    if (clickable && username) {
      e.stopPropagation()
      navigate(`/${username}`)
    } else if (expandable) {
      setIsLightboxOpen(true)
    }
  }

  const handleImageError = (e) => {
    setError(true)
  }

  return (
    <Container>
      {src && !error ? (
        <StyledImage
          src={src}
          alt={alt}
          onClick={handleClick}
          onError={handleImageError}
        />
      ) : (
        <UserPlaceholder onClick={handleClick} />
      )}
      {isLightboxOpen && src && (
        <Lightbox
          key={src}
          mainSrc={src}
          onCloseRequest={() => setIsLightboxOpen(false)}
        />
      )}
    </Container>
  )
}

const uploadImageToStream = async (imageUrl, userId, client, feedToken) => {
  try {
    const response = await axios({
      url: imageUrl,
      method: 'GET',
      responseType: 'blob',
    })

    const file = new File([response.data], 'profile.jpg', {
      type: response.data.type,
    })

    const streamResponse = await client.images.upload(file)
    const streamImageUrl = streamResponse.file

    await updateProfileImage({ userId, imageUrl: streamImageUrl }, feedToken)

    return streamImageUrl
  } catch (error) {
    console.error(
      'Error uploading image to Stream:',
      error.response ? error.response.data : error.message
    )
    return imageUrl
  }
}

export default UserImage
